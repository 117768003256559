.landing-navbar {
    background-color: transparent;
    width: 100vw;
    z-index: 9999;
    position: absolute;
}

.landing {
    background-image: linear-gradient(rgba(0, 0, 0, .2),
    rgba(0, 0, 0, .5)),
    url("//unsplash.it/900/600");
    background-blend-mode: darken;
}

