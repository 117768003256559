@import "_landing.css";
@import "_tailwind.css";
@import "scheme";

/* these make sure it can work in any text element */


.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding-left: 4px;
  padding-right: 4px;
  background: none;
  border: none;
  outline: none;
  min-width: 5rem;
}

.inline-text_copy--active {
  cursor: pointer;
  transition: transform .2s; /* Animation */
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  border-bottom: 1px solid #666666;
  text-align: left;
}

.inline-text_copy--active:hover {
  border-bottom: 1px dashed #999999;
  color: black;
  background-color: $rgba-primary-1;
  transform: scale(1.5);
}


.OKR::before {
  content: "灰 熊";
  display: block;
  font-size: 2rem;
  font-weight: 100;
  position: relative;
  top: 30px;
  left: 7px;
  font-weight: bold;
  font-family: 'Open Sans', 'Noto Serif TC', 'Microsoft Yahei', 'WenQuanYi Micro Hei', 'ST Heiti';
}

.IDEALLABS::after {
  content: "";
  display: block;
  width: 5rem;
  height: 4px;
  background-color: #6210ee;
  position: relative;
}

.centerXY {
  display: grid;
  place-items: center;
}

.icon-button {
  color: $color-primary-2;
  cursor: pointer;

  &:hover {
    color: white;
  }
}

.login-input {
  background-color: $rgba-primary-4;
  color: white;
  letter-spacing: 2px;

  &:focus {
    background-color: $rgba-primary-2;
    outline-color: $rgba-primary-1;
    border-radius: 4px;
  }

  &:active {
    border-radius: 4px;
  }
}

.text-link-on-dark {
  color: $rgba-primary-2;
  cursor: pointer;
  letter-spacing: 2px;

  &:focus {
    background-color: $rgba-primary-1;
  }
}

.select-file {
  cursor: pointer;
  letter-spacing: 2px;
  font-weight: bold;
  color: $rgba-primary-2;

  &:hover {
    color: $rgba-primary-3;
    text-decoration: underline;
    border-bottom-style: dot-dash;
    border-bottom-width: thin;
    border-bottom: $rgba-primary-3;
  }
}

.timestamp {
  font-weight: lighter;
  color: $rgba-primary-2;
}

.board-header {
  background-color: white;
  padding-left: 8px;
  border-radius: 4px;
}

$object-panel-height: 4rem;

.object-title {
  margin-top: 16px;
  height: $object-panel-height;

  &:hover {
    border-bottom: 1px dotted #000 !important;
  }
}

$sidebar-width: 30rem;
$left: calc(100vw - #{sidebar-width});

.glossover {
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(22, 22, 22, 0.5);
}

.sidebar {
  background-color: $color-primary-4;
  width: $sidebar-width;
  height: 100vh;
  display: none;
  position: absolute;
  top: 0;
  left: calc(100vw - 30rem);
  z-index: 23;

  .title {
    margin-right: 8px;
  }
}

.pod {
  background-color: white;

  .pod-header {
    padding-left: 8px;
    padding-right: 8px;
    font-weight: bold;
    font-size: 1.25rem;
    letter-spacing: 2px;
    color: $color-primary-4;
    background-color: $color-primary-1;
  }

  .pod-content {
    padding: 8px;
    background-color: white;
  }
}


.box-shadow-red {
  box-shadow: 0 0 10px red;
}

.box-shadow-blue {
  box-shadow: 0 0 10px #357edd;
}
.box-shadow-gray {
  box-shadow: 0 0 10px #777;
}
.box-shadow-yellow {
  box-shadow: 0 0 10px yellow;
}
.box-shadow-green{
  box-shadow: 0 0 10px #19a974;
}

.square-1rem {
  width: 1rem !important;
  height: 1rem !important;
}

.selected-border {
  outline: none;
}

.unselected-border {
  border: 3px dotted black;
}

.light-pad {
  $padSize: 3rem;
  width: $padSize;
  height: $padSize;
  cursor: pointer;
  border-radius: 50%;
}

.input-heading {
  font-weight: bold;
  letter-spacing: 2px;
}

.progress {
  color: $color-primary-2;
  justify-items: center;
  font-size: 1.25rem;
  font-weight: bolder;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  .current {
    color: gray;
  }
  .target {
    color: black;
  }
  .unit {
    color: black;
    font-size: 0.75rem;
  }
  .ratio {
    background-color: $color-primary-2;
    margin-left: 8px;
    margin-right: 8px;
    color: $color-primary-1;
    padding-left:4px;
    padding-right: 4px;
    border-radius: 4px;
    min-width: 4rem;
    text-align: right;
    font-size: 1.2rem;

  }
}

.btn {
  padding: 8px;
  max-width: 128px;
  text-align: center;

  &-subtle {
    color: gray;
  }
  &-subtle:hover {
    color: white;
  }
  &-dangerous {
   color: white;
    background-color: deeppink;
  }
}


.side-bar {
  background-color: $color-primary-4;
  color: $color-primary-1;
  $padding-y: 0.5rem;
  .license {
    color: $color-primary-2;
  }
  .menu-item {
    padding-top: $padding-y;
    padding-bottom: $padding-y;
    $border-width: 0.75rem;
    color: $color-primary-2;
    font-weight: bold;
    border-left-style: solid ;
    border-left-width: $border-width;
    border-right-style: solid ;
    border-right-width: $border-width;
    border-color: transparent;
    &:hover {
      border-color: $color-primary-1;
    }
  }
  .active {
    border-left-color: $color-primary-3 !important;
    border-right-color: #ececee !important;
    &:hover {
      border-color: $color-primary-1;
      border-left-style: solid;
    }
  }
}



