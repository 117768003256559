// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=33Z0V0kkCmM5PQSdivbs8e1Ff50 */

// Feel free to copy&paste color codes to your application */

// screenshot: https://res.cloudinary.com/twks/image/upload/v1596138610/okr/colorscheme.png.png

// As hex codes */

$color-primary-0: #393b7f; // Main Primary color */
$color-primary-1: #c3c4e7;
$color-primary-2: #7071ad;
$color-primary-3: #13154e;
$color-primary-4: #03031c;


$color-secondary-1-0: #A0B03F; // Main Secondary color (1) */
$color-secondary-1-1: #F6FDCF;
$color-secondary-1-2: #E3F18D;
$color-secondary-1-3: #5F6D0D;
$color-secondary-1-4: #212700;

$color-secondary-2-0: #B56341; // Main Secondary color (2) */
$color-secondary-2-1: #FFDED1;
$color-secondary-2-2: #F8B091;
$color-secondary-2-3: #702B0D;
$color-secondary-2-4: #280C00;

// As RGBa codes */

$rgba-primary-0: rgba(57, 59, 127, 1); // Main Primary color */
$rgba-primary-1: rgba(195, 196, 231, 1);
$rgba-primary-2: rgba(112, 113, 173, 1);
$rgba-primary-3: rgba(19, 21, 78, 1);
$rgba-primary-4: rgba(3, 3, 28, 1);

$rgba-secondary-1-0: rgba(160, 176, 63, 1); // Main Secondary color (1) */
$rgba-secondary-1-1: rgba(246, 253, 207, 1);
$rgba-secondary-1-2: rgba(227, 241, 141, 1);
$rgba-secondary-1-3: rgba(95, 109, 13, 1);
$rgba-secondary-1-4: rgba(33, 39, 0, 1);

$rgba-secondary-2-0: rgba(181, 99, 65, 1); // Main Secondary color (2) */
$rgba-secondary-2-1: rgba(255, 222, 209, 1);
$rgba-secondary-2-2: rgba(248, 176, 145, 1);
$rgba-secondary-2-3: rgba(112, 43, 13, 1);
$rgba-secondary-2-4: rgba(40, 12, 0, 1);


// Generated by Paletton.com © 2002-2014 */
// http://paletton.com */


.bg-color-primary-4 {
  background-color: $rgba-primary-4;
}

.bg-color-primary-3 {
  background-color: $rgba-primary-3;
}

.bg-color-primary-2 {
  background-color: $rgba-primary-2;
}

.bg-color-primary-1 {
  background-color: $rgba-primary-1;
}

.bg-color-primary-0 {
  background-color: $rgba-primary-0;
}

.color-primary-4 {
  color: $rgba-primary-4;
}

.color-primary-3 {
  color: $rgba-primary-3;
}

.color-primary-2 {
  color: $rgba-primary-2;
}

.color-primary-1 {
  color: $rgba-primary-1;
}

.color-primary-0 {
  color: $rgba-primary-0;
}
