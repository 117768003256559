.font-hairline {
    font-weight: 100;
}

.font-thin {
    font-weight: 200;
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.font-extrabold {
    font-weight: 800;
}

.font-black {
    font-weight: 900;
}

.letterSpacing-2 {
    letter-spacing: 2px;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

